import React, { useState, useRef, useEffect } from 'react';
import { supabase } from '../../supabaseClient.js';
import loginImage from '../../assets/images/login.png';
import Grid from '@mui/material/Grid2';
import { Container, IconButton, Typography, MenuItem, Select, FormControl, InputLabel, Box, TextField, Stack, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { setLoginStatus, setUserDetails } from '../../store.js';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TikcareLogo } from '../../assets/svg/TikcareLogo.svg';
import useAlert from '../../alert/useAlert.js';


const LoginScreen = () => {
  const [currentScreen, setCurrentScreen] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [loginDetailsSubmitted, setLoginDetailsSubmitted] = useState(false);
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const hasMounted = useRef(false);
  const { setAlert } = useAlert();

  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  useEffect(() => {
    if (hasMounted.current) { return; }
    dispatch(setLoginStatus(false));
    dispatch(setUserDetails(null));
    hasMounted.current = true;
  }, []);

  useEffect(() => {
    setOtpSubmitted(false);
    setLoginDetailsSubmitted(false);
    setEmailSubmitted(false);
  }, [ currentScreen ]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getUserDetails = async (id) => {
    let { data, error } = await supabase.from('useraccounts').select('*').eq('id', id);
    if (error) {
      console.error("Error: ", error);
      return null;
    } else {
      return data[0];
    }
  }

  const handleLogin = async () => {
    if(!email || !password) {
      setAlert('Email and password are required', 'error');
      return;
    }
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    
    if (error) {
      setAlert(error.message, 'error');
    } else {
      // sendOtp();
      // setCurrentScreen('otp'); 
      const userDetails = await getUserDetails(data.user.id);
      if(!userDetails) {
        setAlert('User not found. Please create an account', 'error');
        return;
      }
      dispatch(setLoginStatus(true));
      dispatch(setUserDetails({ id: data.user.id, email: userDetails.email, name: userDetails.name, practicetype: userDetails.practicetype }));
      navigate('/consultation'); 
    }
  };

  const sendOtp = async () => {
    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        shouldCreateUser: false,
      },
    })
    if (error) {
      setAlert('Error sending OTP', 'error');
    } else {
      console.log('OTP sent successfully');
    }
  }

  const handleOtpVerify = async () => {
    const { error } = await supabase.auth.verifyOtp({
      email,
      token: otp,
      type: 'email',
    });
    if (error) {
      setAlert('The OTP entered is incorrect. Please try again.', 'error');
    } else {
      dispatch(setUserDetails({ email: email, password: password }));
      dispatch(setLoginStatus(true));
      navigate('/consultation');
    }
  };

  const handleSendResetLink = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: process.env.REACT_APP_PASSWORD_RESET_LINK,
      // redirectTo: 'https://localhost:3000/updatePassword',
    });
    if (error) {
      console.error(error.message);
    } else {
      setCurrentScreen('resetPassword');
    }
  };

  return (
    <Container disableGutters maxWidth={false}> 
      <Grid container spacing={2} sx={{ minHeight: '100vh', minWidth: '100vw', marginTop: -3 }}>
        <Grid size={12} sx={{ backgroundColor: '#B9F4F2', height: '90px', padding: '15px 10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <TikcareLogo onClick={() => { navigate('/'); }} style={{cursor: 'pointer'}}/>
        </Grid>
        {
          currentScreen === 'login' ?
            <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
              <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px', marginBottom: '20px'}}>Login</Typography>
                <Typography variant="body1"> Don’t have an account? <span onClick={() => { navigate('/signup') }} style={{ cursor: 'pointer', color: '#29C0BB' }}>Sign Up</span> </Typography>
                <form>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  focused
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email" 
                  sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
                  error={loginDetailsSubmitted && !email}
                  helperText={loginDetailsSubmitted && !email && "Email is required"}
                />
                <TextField
                  required
                  fullWidth
                  label="Password"
                  variant="outlined"
                  focused
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }}
                  error={loginDetailsSubmitted && !password}
                  helperText={loginDetailsSubmitted && !password && "Password is required"}
                  slotProps={{
                    input: {
                      endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                      ),
                    }
                  }}
                />
                </form>
                <Typography style={{marginBottom: '20px'}} variant="body1"> <span onClick={() => setCurrentScreen('forgotPassword')} style={{ cursor: 'pointer', color: '#29C0BB' }}>Forgot Password?</span> </Typography>
                <Button onClick={() => { setLoginDetailsSubmitted(true); handleLogin(); }  } variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                  <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Login</Typography>
                </Button>
              </Stack>
            </Grid>
            // : currentScreen === 'otp' ?
            // <Grid size={12} sx={{padding:'50px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
            //   <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
            //     <Typography variant="body1"><span onClick={() => setCurrentScreen('login')} style={{ cursor: 'pointer', color: '#29C0BB' }}>Back to Login</span> </Typography>
            //     <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>Authenticate with OTP</Typography>
            //     <Typography variant="body1">Check your email for an OTP code</Typography>
            //     <TextField
            //       required
            //       fullWidth
            //       variant="outlined"
            //       focused
            //       value={otp}
            //       onChange={(e) => setOtp(e.target.value)}
            //       placeholder="Enter your OTP code" 
            //       sx={{ marginTop: '20px', marginBottom: '16px', backgroundColor: '#f5f5f5' }} 
            //       error={otpSubmitted && !otp}
            //       helperText={otpSubmitted && !otp && "Enter OTP!"}
            //     />
            //     <Typography variant="body1">Didn't receive a code? <span onClick={() => { sendOtp() }} style={{ cursor: 'pointer', color: '#29C0BB' }}>Send again</span> </Typography>
            //     <Button onClick={() => { setOtpSubmitted(true); handleOtpVerify();  }} variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
            //       <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Login</Typography>
            //     </Button>
            //   </Stack>
            // </Grid>
          : currentScreen === 'forgotPassword' ?
            <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
              <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
                <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>Forgot Password</Typography>
                <Typography variant="body1">Remember your password? <span onClick={() => setCurrentScreen('login')} style={{ cursor: 'pointer', color: '#29C0BB' }}>Login</span> </Typography>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  focused
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email" 
                  sx={{ marginTop: '20px', marginBottom: '20px', backgroundColor: '#f5f5f5' }} 
                  error={emailSubmitted && !email}
                  helperText={emailSubmitted && !email && "Email"}
                />
                <Button onClick={() => { setEmailSubmitted(true); handleSendResetLink(); }} variant="outlined" sx={{width: '100%', height: '50px', borderRadius: 30, backgroundColor: '#29C0BB', border: 0}}>
                  <Typography variant="button" sx={{color: '#FFFFFF', fontSize: '15px'}}>Send reset link</Typography>
                </Button>
              </Stack>
            </Grid>          
          : 
          currentScreen === 'resetPassword' ?
          <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
            <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
              <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>A password reset link has been sent to your email!</Typography>
              <Typography variant="body1">Remember your password? <span onClick={() => setCurrentScreen('login')} style={{ cursor: 'pointer', color: '#29C0BB' }}>Login</span> </Typography>
            </Stack>
          </Grid>  
        : null                   
        }
      </Grid>
    </Container>
  );
};

export default LoginScreen;

