import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Container, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TikcareLogo } from '../../assets/svg/TikcareLogo.svg';
import { supabase } from '../../supabaseClient.js';

const EmailVerify = () => {
  const [userid, setUserId] = useState('');
  const navigate = useNavigate();

  React.useEffect(() => {
    verifyUser();
  }, []);

  const verifyUser = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      console.error('Error fetching user: ', error.message);
      return;
    }
    if (user && user.email_confirmed_at) {
      setUserId(user.id);
      await createUser(user.id, user.email, user.created_at);
    } else {
      console.log('User email is not verified yet.');
    }
  };

  const createUser = async ( id, email, created_at ) => {
    let { data, error } = await supabase.from('useraccounts').insert([{ id, email, created_at }]);
    if (error){
      console.error(error);
    }
  }

  return (
    <Container disableGutters maxWidth={false}> 
      <Grid container spacing={2} sx={{ minHeight: '100vh', minWidth: '100vw', marginTop: -3 }}>
        <Grid size={12} sx={{ backgroundColor: '#B9F4F2', height: '90px', padding: '15px 10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <TikcareLogo  />
        </Grid>
        <Grid size={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
            <Stack spacing={3} width={{xs: '80vw', md: '40vw'}} direction="column" alignItems="flex-start" justifyContent="center" style={{backgroundColor: '#f5f5f5', padding: '30px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
            <Typography variant="h6" sx={{fontWeight: 'bold', fontSize: '30px'}}>Email has been verified successfully. Please click <span onClick={() => { navigate('/createProfile', {state: {userid} }) }} style={{ cursor: 'pointer', color: '#29C0BB' }}>here</span> to create your profile and complete the signup process.</Typography>
            </Stack>
        </Grid> 
      </Grid>
    </Container>
  );
};

export default EmailVerify;

