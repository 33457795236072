import * as React from 'react';
import { styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as TikcareLogo } from '../assets/svg/TikcareLogo.svg';
import { ReactComponent as Dashboard} from '../assets/svg/dashboard.svg';
import { ReactComponent as Patient} from '../assets/svg/patient.svg';
import { ReactComponent as Chat} from '../assets/svg/chat.svg';
import { ReactComponent as Scribe} from '../assets/svg/scribe.svg';
import { ReactComponent as Task} from '../assets/svg/task.svg';
import { ReactComponent as CollapseIn} from '../assets/svg/collapse-in.svg';
import { ReactComponent as CollapseOut} from '../assets/svg/collapse-out.svg';
import { ReactComponent as Resource} from '../assets/svg/resource.svg';
import '../App.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setDrawerOpen, setCurrentTab } from '../store.js';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
// const sideMenuOptions = ['Dashboard', 'Patients', 'Chats', 'Scribe Assistant', 'Tasks', 'Resource Library'];
const sideMenuOptions = ['Scribe Assistant'];
// const sideMenuOptions = ['Chats', 'Scribe Assistant'];


const openedMixin = (theme) => ({
  width: drawerWidth,
  boxShadow: `4px 0px 8px rgba(0, 0, 0, 0.2)`, 
  backgroundColor: '#F8F9FA',
  padding: '0 5px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  boxShadow: `4px 0px 8px rgba(0, 0, 0, 0.2)`, 
  backgroundColor: '#F8F9FA',
  padding: '0 5px',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const DrawerHeader = styled('div')(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'space-between' : 'center',
  padding: "0 20px",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const CustomListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'currentTab' && prop !== 'text',
})(({ theme, currentTab, text }) => ({
  '&:hover': {
    backgroundColor: currentTab === text ? '#29C0BB' : theme.palette.action.hover
  },
}));


export default function MiniDrawer() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.globalState.open, shallowEqual);
  const currentTab = useSelector((state) => state.globalState.currentTab, shallowEqual);
  const navigate = useNavigate();

  const changeTab = (tab) => {
    dispatch(setCurrentTab(tab))
    dispatch(setDrawerOpen(false));
    if (tab === 'Chats') {
      navigate('/chats');
    } else if (tab === 'Scribe Assistant') {
      navigate('/consultation');
    }
  }

  return (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader open={open}>
          <TikcareLogo height={50} width={120}/>
          <IconButton onClick={() => {dispatch(setDrawerOpen(open === true ? false : true)); }}>
            {open ? <CollapseIn style={{width: 35, height: 35,  marginTop: 8}}/> : <CollapseOut style={{width: 30, height: 30,  marginTop: 8}}/>}
          </IconButton>
        </DrawerHeader>
        <List>
          {sideMenuOptions.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              {/* {console.log("currentTab: ", currentTab)} */}
              <CustomListItemButton 
                currentTab={currentTab}
                text={text}
                onClick={() => { changeTab(text); }}
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                    borderRadius: '16px',
                    backgroundColor: currentTab === text ? '#29C0BB' : 'inherit',
                  },
                  open
                    ? {
                        justifyContent: 'initial',
                      }
                    : {
                        justifyContent: 'center',
                      },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: 'auto',
                        },
                  ]}
                >
                  {/* {index === 0 ? <Dashboard /> : index === 1 ? <Patient /> : index === 2 ? <Chat /> : index === 3 ? <Scribe /> : index === 4 ? <Task /> : <Resource />} */}
                  <Scribe />
                  {/* {index === 0 ? <Chat /> : <Scribe /> } */}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </CustomListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
  );
}
